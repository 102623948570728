import { FrontConfiguration } from "app/config/front-configuration";
import { AuthRoles } from "app/main/pages/authentication/shared/model/enum/auth-roles.enum";

function getAttachmentType(attachmentURL) {
    const fileTypeDefault = "application/json";

    const filesFormats = ["docx", "doc", "xlsx", "xlsm", "xltx", "xltm", "xlam"];
    const fileTypes = {
        txt: "text/plain",
        png: "image/png",
        jpeg: "image/jpeg",
        pdf: "application/pdf",
        file: "application/octet-stream"
    }

    const type = attachmentURL.split('.').slice(-1)[0];

    return fileTypes[type] || (filesFormats.includes(type) && fileTypes['file']) || fileTypeDefault;
}


/**
 * Genera un dato de tipo fecha a una fecha de tipo string con el formato indicado
 * @param {Date} date - Fecha a formatear
 * @param {string} format - Tipo de formato (p.ej. 'yyyy-MM-dd')
 * @returns {string} Un string de fecha con formato
 */
export const getDateWithFormat = function (date: Date, format: string) {
    const z = {
        M: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        m: date.getMinutes(),
        s: date.getSeconds(),
    };
    format = format.replace(/(M+|d+|h+|m+|s+)/g, (v) => {
        return ((v.length > 1 ? '0' : '') + z[v.slice(-1)]).slice(-2);
    });

    return format.replace(/(y+)/g, (v) => {
        return date.getFullYear().toString().slice(-v.length);
    });
};


export const downloadFile = (blobToDownload: Blob, fileURL: string, filename: string, type?: string) => {
    const blob = new Blob([blobToDownload], { type: type ? type : getAttachmentType(fileURL) });
    const objectUrl = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = objectUrl
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(objectUrl);
};


export const replaceParameterInTemplate = (template: any) => {
    const templateCopy = { ...template };
    if (templateCopy) {
        let indexBodyParams = 0;
        for (const [key, value] of Object.entries(templateCopy)) {
            if (templateCopy['headerText'] && key.toLowerCase().match(/parameterheader/) && value) {
                templateCopy['headerText'] = templateCopy['headerText'].replace(/{\{1\}}/, value)
            }

            if (key.toLowerCase().match(/parameter[0-9]*body/) && value) {
                indexBodyParams++;
                templateCopy['bodyText'] = templateCopy['bodyText'].split(`{{${indexBodyParams}}}`).join(value);
            }
        }

        if (!templateCopy['headerText'] && templateCopy['headerType'] && templateCopy['headerType'] !== 'TEXT') {
            templateCopy['headerText'] = templateCopy['parameterHeader'];
        }
    }
    return templateCopy;
}

export const checkUserConfiguration = (expectedConfigurations: Array<string>): boolean => {
    let hasConfigurations = false;
    const currentUser = JSON.parse(localStorage.getItem('userLoginSession'));
    if (currentUser) {
        const frontConfigurations = currentUser.frontConfigurations;
        if (frontConfigurations && frontConfigurations.configurations && frontConfigurations.configurations.length > 0) {
            // Merge user configurations
            let userConfigurations = [...frontConfigurations.configurations, ...getConfigurationsValue(frontConfigurations.configurationsValues)];

            for (const expectedConfiguration of expectedConfigurations) {
                if (!expectedConfiguration) continue;
                hasConfigurations = userConfigurations.includes(expectedConfiguration);
                if (hasConfigurations) break;
            }
        } else {
            console.warn("Current user configuration not found")
        }
    } else {
        console.warn("Current user not found")
    }

    return hasConfigurations;
}

function getConfigurationsValue(configurationsValues: any[]) {
    if (!configurationsValues || configurationsValues.length === 0) return [];
    return configurationsValues.map(obj => Object.keys(obj)[0]);
}


export const getConfigurationValue = (frontConfig: FrontConfiguration): string => {
    let configValue = '';
    const currentUser = JSON.parse(localStorage.getItem('userLoginSession'));
    if (currentUser) {
        const frontConfigurations = currentUser.frontConfigurations;
        if (frontConfigurations && frontConfigurations.configurationsValues && frontConfigurations.configurationsValues.length > 0) {
            frontConfigurations.configurationsValues.forEach(config => {
                const [key, value] = Object.entries(config)[0];
                if (key === frontConfig) {
                    configValue = value + '';
                    return;
                }
            })
        } else {
            console.warn("Current user configuration not found")
        }
    } else {
        console.warn("Current user not found")
    }
    return configValue;
}

export const checkCurrentPartner = (partnerId: number): boolean => {
    const currentUser = JSON.parse(localStorage.getItem('userLoginSession'));
    let isCurrentPartner = false;
    if (currentUser) {
        const frontConfigurations = currentUser.frontConfigurations;
        if (frontConfigurations && frontConfigurations.partner && frontConfigurations.partner === partnerId) {
            isCurrentPartner = true;
        } else {
            console.warn("Current user configuration not found")
        }
    } else {
        console.warn("Current user not found")
    }
    return isCurrentPartner;
}

/**
 * TODO: validacion temporal hasta unificar configuraciones con el rol del usuario
 * metodo para controlar las configuracion solo para doppler, luego se debe cambiar por los metodos comunues a esta validacion.
 */
export const checkByConfigAndRoleDoppler = (expectedConfigurations: Array<string>, expectedRole: AuthRoles): boolean => {
    const currentUser = JSON.parse(localStorage.getItem('userLoginSession'));
    let hasConfiguration = false;
    if (currentUser) {
        hasConfiguration = checkUserConfiguration(expectedConfigurations);
        if (hasConfiguration && (currentUser.frontConfigurations.partner === 2 && !currentUser.authorities.includes(expectedRole))) {
            hasConfiguration = false;
        }
    } else {
        console.warn("Current user not found")
    }

    return hasConfiguration;
}

export const checkRolAccess = (expectedRoles: AuthRoles[]) => {
    const currentUser = JSON.parse(localStorage.getItem('userLoginSession'));
    let hasAccess = false;
    if (currentUser) {
        const authorities: string[] = currentUser.authorities || [];
        if (authorities && authorities.length && expectedRoles.some(expectedRole => authorities.includes(expectedRole))) {
            hasAccess = true;
        } else {
            console.warn("Current user authorities not found")
        }
    } else {
        console.warn("Current user not found")
    }
    return hasAccess;
}